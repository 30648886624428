import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from 'classnames';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showProfile } from "../actions/ModalAction";


const SignUouButton = props => {
    const handleSignOut = () => {
        localStorage.removeItem('Authorization');
        window.location.href = '/login';
    };

    return (
        <div className="mkdf-wishlist-widget-holder">
            <a href="#" className="mkdf-wishlist-widget-link" onClick={handleSignOut}>
                <span className="mkdf-wishlist-widget-icon">
                    <FontAwesomeIcon icon={faSignOutAlt} size="sm" />
                    <span className="mkdf-wishlist-title"> Sign out </span>
                </span>
            </a>
        </div>
    );
};

const CartButton = props => {
    return (
        <div className="mkdf-shopping-cart-holder">
            <div className="mkdf-shopping-cart-inner">
                <a itemProp="url" className="mkdf-header-cart mkdf-header-cart-icon-pack hover-pointer" href="/cart">
                    <span className=" mkdf-cart-icon">
                        <span aria-hidden=" true" className=" dripicons-cart" />
                        <span className="mkdf-cart-title">My Cart</span>
                    </span>
                    {/*<span className=" mkdf-cart-number">0</span>*/}
                </a>
                {/*<div className=" mkdf-shopping-cart-dropdown">*/}
                {/*    <ul>*/}
                {/*        <li className="mkdf-empty-cart">No products in the cart.</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

const _ProfileButton = props => {
    return (

        //     <li id="nav-menu-item-11" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub wide mkdf-wide-menu-in-grid">
        //     <a href="/about-us" className><span className="item_outer"><span className="item_text">About us</span><i className="mkdf-menu-arrow fa fa-angle-down"/></span></a>
        // </li>
        <div className="mkdf-shopping-cart-holder">
            <div className="mkdf-shopping-cart-inner">
                <a href="/profile" itemProp="url" className="mkdf-header-cart mkdf-header-cart-icon-pack hover-pointer"> 
                {/* onClick={() => props.showProfile()}> */}
                    <span className=" mkdf-cart-icon">
                        <span aria-hidden=" true" className="dripicons-user" />
                        <span className="mkdf-cart-title">My Profile</span>
                    </span>
                    {/*<span className=" mkdf-cart-number">0</span>*/}
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        show: state.modal.profile.visible,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showProfile: () => dispatch(showProfile()),
    };
};

const ProfileButton = connect(mapStateToProps, mapDispatchToProps)(_ProfileButton);


class Header extends Component {
    state = {
        hide: false,
        sticky: false,
    };

    componentDidMount() {
        const self = this;
        window.addEventListener(" scroll", function (event) {
            const scroll = this.scrollY;
            self.setState({
                sticky: scroll > 200,
                hide: scroll > 20,
            });
        });
    }

    render = () => {
        const { sticky, hide } = this.state;

        return (
            <header className={classes(" mkdf-page-header", { 'position-absolute': !sticky })}>
                <div className={classes({ " mkdf-menu-area": !sticky, 'mkdf-sticky-header': hide, 'header-appear': sticky })}>
                    <div className=" mkdf-grid">
                        <div className=" mkdf-vertical-align-containers">
                            <div className=" mkdf-position-left">
                                <div className=" mkdf-position-left-inner">
                                    {this.renderLogo()}
                                    {this.renderNav()}
                                </div>
                            </div>
                            {this.renderRightItems()}
                        </div>
                    </div>
                </div>
            </header>
        );
    };

    renderLogo = () => {
        return (
            <div className=" mkdf-logo-wrapper">
                <a itemProp=" url" href=" index.html" style={{ height: '37px' }}>
                    <img itemProp=" image" className=" mkdf-normal-logo" src="/assets/img/logo.png" alt=" logo" />
                </a>
            </div>
        );
    };

    renderNav = () => {
        const { page } = this.props;
        if (page === 'login')
            return null;

        return (
            <nav className=" mkdf-main-menu mkdf-drop-down mkdf-default-nav">
                <ul id=" menu-main-menu" className=" clearfix">
                    <li id=" nav-menu-item-7" className=" menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub narrow">
                        <a href="/" className=" current ">
                            <span className="item_outer">
                                <span className="item_text">Home</span>
                                <i className="mkdf-menu-arrow fa fa-angle-down" />
                            </span>
                        </a>
                    </li>
                    <li id="nav-menu-item-9" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                        <a href="/contact-us" className><span className="item_outer"><span className="item_text">Contact us</span><i className="mkdf-menu-arrow fa fa-angle-down" /></span></a>
                    </li>
                    <li id="nav-menu-item-10" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                        <a href="/photo-gallery" className><span className="item_outer"><span className="item_text">Gallery</span><i className="mkdf-menu-arrow fa fa-angle-down" /></span></a>
                    </li >
                    <li id="nav-menu-item-11" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub wide mkdf-wide-menu-in-grid">
                        <a href="/about-us" className><span className="item_outer"><span className="item_text">About us</span><i className="mkdf-menu-arrow fa fa-angle-down" /></span></a>
                    </li>
                </ul>
            </nav>
        );
    };


    renderRightItems = () => {
        const { page } = this.props;
        if (page === 'login')
            return null;

        return (
            <div className="mkdf-position-right">
                <div className="mkdf-position-right-inner">
                    <CartButton />
                    <ProfileButton />
                    <SignUouButton />
                </div>
            </div>
        );
    };
}

export default withRouter(Header);

import React, {Component} from 'react';
import '../../App.css';
import CategoriesFilter from "./CategoriesFilter";
import Pagination from "../../Components/Pagination";
import axios from "axios";
import Product from "./Product";
import {APIs} from "../../APIs";

class ErrorFeedback extends Component {
    render = () => {
        return (
            <div className="mkdf-page-not-found">
                <h1 className="mkdf-404-title">
                    404 </h1>
                <h3 className="mkdf-404-subtitle">
                    Page not found </h3>
                <p className="mkdf-404-text">
                    Oops! The page you are looking for does not exist. It might have been moved or deleted. </p>
            </div>
        );
    };
}

export default ErrorFeedback;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { APIs } from '../APIs';
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import LoadingFeedback from './Home/LoadingFeedback';

class UpdateProfile extends Component {

    render = () => {
        return (
            <Fragment>
                <div className="mkdf-wrapper">
                    <div className="mkdf-wrapper-inner">
                        <Header />
                        <UpdateProfileContent />
                        <Footer />
                    </div>
                </div>
            </Fragment>
        );
    };
}

class UpdateProfileContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            civicNumber: "",
            streetName: "",
            apartmentNumber: "",
            postalCode: "",
            city: "",
            loading: false,
            error: false,
            invalid: "hidden",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    };

    render = () => {
        return (
            <div className="mkdf-row-grid-section-wrapper " style={{ marginTop: 200, minHeight: 'calc(100vh - 260px)' }}>
                <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1528800275215 vc_row-o-content-top vc_row-flex">
                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12">
                            <div className="vc_column-inner">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderContent = () => {
        const { user, loading } = this.state;
        console.log("user is")
        console.log(user)
        if (loading) {
            return (
                <LoadingFeedback />
            );
        }
        return (
            <div className="wpb_wrapper">

                <div className="mkdf-section-title-holder mkdf-st-subtitle-pos-under mkdf-content-aligment-center">
                    <div className="mkdf-st-inner">
                        <div>
                            <h1 className="mkdf-st-title">  Update your address </h1>
                        </div>
                    </div>
                </div>
                <div
                    className="mkdf-elements-holder mkdf-one-column  mkdf-responsive-mode-768 ">
                    <div className="mkdf-eh-item     "

                        data-item-class="mkdf-eh-custom-5684">
                        <div className="mkdf-eh-item-inner">
                            <div
                                className="mkdf-eh-item-content mkdf-eh-custom-5684"
                                style={{ padding: "57px 40px 55px" }}>
                                <div role="form" className="wpcf7"
                                    id="wpcf7-f1856-p668-o5"
                                    lang="en-US" dir="ltr">
                                    <div className="screen-reader-response" />
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    renderForm = () => {
        const { invalid } = this.state;

        const handleSpinner = () => {
            return (
                <div className="sweet-loading">
                    <span style={{ fontSize: '16px' }}>Updating...</span>
                    <PulseLoader
                        style={{
                            display: 'block',
                            margin: '0 auto',
                            borderColor: 'green',
                        }}

                        size={5}
                        color={"white"}
                        loading={true}
                    />
                </div>
            );
        };

        const handleTriangle = () => {
            return (
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span> Unable to Update Address</span>
                </div>
            );
        };

        const handleValue = () => {
            const { loading } = this.state;
            const { error } = this.state;
            const spinner = handleSpinner();
            const triangle = handleTriangle();

            console.log(spinner);

            if (loading)
                return spinner;
            if (error)
                return triangle;
            return 'Save';
        }

        return (
            <form
                className="wpcf7-form cf7_custom_style_1"
                // noValidate="novalidate" 
                onSubmit={this.handleSubmitForm}
            >
                <div style={{ display: "none" }}>
                    <input type="hidden"
                        name="_wpcf7"
                        value="1856" />
                    <input type="hidden"
                        name="_wpcf7_version"
                        value="5.1.6" />
                    <input type="hidden"
                        name="_wpcf7_locale"
                        value="en_US" />
                    <input type="hidden"
                        name="_wpcf7_unit_tag"
                        value="wpcf7-f1856-p668-o5" />
                    <input type="hidden"
                        name="_wpcf7_container_post"
                        value="668" />
                </div>
                <div className="mkdf-centered-cf7-btn" style={{ margin: "0 0 5px" }}>
                    <div>
                        <span className="wpcf7-form-control-wrap ">
                            <input
                                onChange={this.handleChange}
                                type="text"
                                name="civicNumber"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Civic number"
                                required
                                value={this.state.civicNumber}
                            />
                        </span>
                    </div>
                    <div>
                        <span className="wpcf7-form-control-wrap ">
                            <input
                                onChange={this.handleChange}
                                type="text"
                                name="streetName"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Street name"
                                required
                                value={this.state.streetName}
                            />
                        </span>
                    </div>
                    <div>
                        <span className="wpcf7-form-control-wrap ">
                            <input
                                onChange={this.handleChange}
                                type="text"
                                name="apartmentNumber"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Apartment number"
                                required
                                value={this.state.apartmentNumber}
                            />
                        </span>
                    </div>
                    <div>
                        <span className="wpcf7-form-control-wrap ">
                            <input
                                onChange={this.handleChange}
                                type="text"
                                name="postalCode"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Postal code"
                                required
                                value={this.state.postalCode}
                            />
                        </span>
                    </div>
                    <div>
                        <span className="wpcf7-form-control-wrap ">
                            <input
                                onChange={this.handleChange}
                                type="text"
                                name="city"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="City"
                                required
                                value={this.state.city}
                            />
                        </span>
                    </div>

                    <div className="invalid" style={{ visibility: invalid }}>
                        There are a problem
                    </div>

                    <div
                        // onClick={this.handleSubmitForm}
                        className="mkdf-btn mkdf-btn-medium mkdf-btn-solid mkdf-btn-icon mkdf-btn-icon-animate mkdf-btn-cf7-huge">

                        {/* <Button
                            // type="submit"
                            // value={handleValue()}
                            className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon"
                        > */}
                        <input className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon"
                            style={{ width: '100%', height: '100%' }}
                            type="submit" value={handleValue()} />
                        {/* </Button> */}
                    </div>
                </div>
            </form >
        );
    };


    componentDidMount = () => {
        this.fetchProfile();
    };


    fetchProfile = () => {
        this.setState({
            ...this.state,
            loading: true,
        });

        Axios.get(APIs.ME).then(
            res => {
                const { civicNumber, streetName, apartmentNumber, postalCode, city } = res.data;

                this.setState({
                    ...this.state,
                    civicNumber: civicNumber,
                    streetName: streetName,
                    apartmentNumber: apartmentNumber,
                    postalCode: postalCode,
                    city: city,
                    loading: false,
                });
            }
        );

    };


    handleSubmitForm = (event) => {
        event.preventDefault();

        const { civicNumber, streetName, apartmentNumber, postalCode, city } = this.state;

        this.setState({
            ...this.state,
            loading: true,
            error: false,
        });

        const data = {
            data: {
                civicNumber: civicNumber,
                streetName: streetName,
                apartmentNumber: apartmentNumber,
                postalCode: postalCode,
                city: city
            }
        };

        Axios.put(APIs.UPDATE_PROFILE, data).then(
            res => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                    onClose: (toast) => {
                        window.location.href = "/profile";
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: 'successfully update address'
                })

                this.setState({
                    ...this.state,
                    loading: false,
                    error: false,
                    invalid: "hidden",
                })
            },
        ).catch(e => {
            if (e.response) {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: false,
                    invalid: "visible",
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: true,
                    invalid: "hidden",
                });
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

}

export default UpdateProfile;

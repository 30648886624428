import React from 'react';
import '../../App.css';

import {Component, Fragment} from "react";
import axios from 'axios';
import {APIs} from "../../APIs";
import Products from "./Products";
import {withRouter} from "react-router-dom";
import {Button, Image, Spinner} from "react-bootstrap";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClipLoader, MoonLoader, PulseLoader} from "react-spinners";
import Header from "../../Layouts/Header";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loading: false,
            error: false,
            invalid: "hidden",
        }
    }

    render = () => {
        return (
            <div className="mkdf-full-width login">
                <div className="mkdf-full-width-inners">
                    <div className="mkdf-grid-row">
                        <div className="mkdf-page-content-holder mkdf-grid-col-12">
                            <div className="mkdf-row-grid-section-wrapper mkdf-disabled-bg-image-bellow-768">
                                <div className="mkdf-row-grid-section">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1528888583995 vc_row-has-fill">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <Image src={"/assets/img/logo.png"} width={350} style={{margin:'auto', display: 'block'}}/>
                                                {this.renderLogin()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    renderLogin = () => {
        return (
            <div className="mkdf-wrapper">
                <div className="mkdf-wrapper-inner">
                    <div className="mkdf-content">
                        <div className="mkdf-content-inner">
                            <div className="">
                                <div className="mkdf-full-width-inner">
                                    <div className="mkdf-grid-row">
                                        <div className="mkdf-page-content-holder mkdf-grid-col-12">
                                            <div className="mkdf-row-grid-section-wrapper ">
                                                <div className="mkdf-row-grid-section">
                                                    <div
                                                        className="vc_row wpb_row vc_row-fluid vc_custom_1528800275215 vc_row-o-content-top vc_row-flex">
                                                        <div
                                                            className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div
                                                                        className="mkdf-elements-holder   mkdf-one-column  mkdf-responsive-mode-768 ">
                                                                        <div className="mkdf-eh-item     "
                                                                             style={{backgroundColor: "#9ad8d3", borderRadius: "10px"}}
                                                                             data-item-class="mkdf-eh-custom-5684">
                                                                            <div className="mkdf-eh-item-inner">
                                                                                <div
                                                                                    className="mkdf-eh-item-content mkdf-eh-custom-5684"
                                                                                    style={{padding: "57px 40px 55px"}}>
                                                                                    <div role="form" className="wpcf7"
                                                                                         id="wpcf7-f1856-p668-o5"
                                                                                         lang="en-US" dir="ltr">
                                                                                        <div className="screen-reader-response"/>
                                                                                        {this.renderForm()}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_empty_space" style={{height: "80px"}}>
                                                                        <span className="vc_empty_space_inner"/></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderForm = () => {
        const {invalid} = this.state;

        const handleSpinner = () => {
            return (
                <div className="sweet-loading">
                    <span style={{fontSize: '16px'}}>Signing in</span>
                    <PulseLoader
                        style={{
                            display: 'block',
                            margin: '0 auto',
                            borderColor: 'green',
                        }}

                        size={5}
                        color={"white"}
                        loading={true}
                    />
                </div>
            );
        };

        const handleTriangle = () => {
            return (
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <span> Unable to Login</span>
                </div>
            );
        };

        const handleValue = () => {
            const {loading} = this.state;
            const {error} = this.state;
            const spinner = handleSpinner();
            const triangle = handleTriangle();

            console.log(spinner);

            if (loading)
                return spinner;
            if (error)
                return triangle;
            return 'Login';
        }

        return (
            <form
                className="wpcf7-form cf7_custom_style_1"
                noValidate="novalidate">
                <div style={{display: "none"}}>
                    <input type="hidden"
                           name="_wpcf7"
                           value="1856"/>
                    <input type="hidden"
                           name="_wpcf7_version"
                           value="5.1.6"/>
                    <input type="hidden"
                           name="_wpcf7_locale"
                           value="en_US"/>
                    <input type="hidden"
                           name="_wpcf7_unit_tag"
                           value="wpcf7-f1856-p668-o5"/>
                    <input type="hidden"
                           name="_wpcf7_container_post"
                           value="668"/>
                </div>
                <h2 style={{textAlign: "center", color: "#FFF", margin: "0 0 26px"}}>Sign in</h2>
                <div className="mkdf-centered-cf7-btn" style={{margin: "0 0 5px"}} onClick={this.handleSubmitForm}>
                    <div>
                        <span className="wpcf7-form-control-wrap your-email">
                            <input
                                onChange={this.handleChange}
                                type="email"
                                name="email"
                                size="40"
                                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Your email"
                                required
                            />
                        </span>
                    </div>
                    <div>
                        <span
                            className="wpcf7-form-control-wrap your-password">
                                <input
                                    onChange={this.handleChange}
                                    type="password"
                                    name="password"
                                    size="40"
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Your Password"
                                    required
                                />
                        </span>
                    </div>

                    <div className="invalid" style={{visibility: invalid}}>
                        Invalid email or password
                    </div>

                    <div
                        className="mkdf-btn mkdf-btn-medium mkdf-btn-solid mkdf-btn-icon mkdf-btn-icon-animate mkdf-btn-cf7-huge">
                        <Button

                            // value={handleValue()}
                            className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon"
                        >
                            {handleValue()}

                        </Button>
                    </div>
                </div>
            </form>
        );
    };


    handleSubmitForm = (event) => {
        event.preventDefault();

        const {email, password} = this.state;

        this.setState({
            ...this.state,
            loading: true,
            error: false,
        });

        const data = {
            email: email,
            password: password,
        };

        axios.post(APIs.LOGIN_API, data).then(
            res => {
                const {id: auth} = res.data;
                localStorage.setItem("Authorization", auth);
                window.location.href = "/";

                this.setState({
                    ...this.state,
                    loading: false,
                    error: false,
                    invalid: "hidden",
                })
            },
        ).catch(e => {
            if (e.response) {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: false,
                    invalid: "visible",
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: true,
                    invalid: "hidden",
                });
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };
}


export default withRouter(Login);

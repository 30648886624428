import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect } from "react-redux";
import classes from 'classnames';
import { hideProductDetails } from "../../actions/ModalAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import Quantity from "./Quantity";
import StarRate from "./StarRate";
import axios from "axios";
import { APIs } from "../../APIs";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Carousel = (props) => {
    const { images } = props;

    const renderArrowPrev = (handler, hasPrev) => {
        return (
            <div className={classes("owl-prev", { disabled: !hasPrev })} onClick={handler}>
                <span className="mkdf-prev-icon">
                    <span className="dripicons-arrow-thin-left" />
                </span>
            </div>
        );
    };

    const renderArrowNext = (handler, hasNext) => {
        return (
            <div className={classes("owl-next", { disabled: !hasNext })} onClick={handler}>
                <span className="mkdf-next-icon">
                    <span className="dripicons-arrow-thin-right" />
                </span>
            </div>
        );
    };

    return (
        <ReactCarousel
            centerSlidePercentage={true}
            showArrows={true}
            renderThumbs={() => null}
            showStatus={false}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}>
            {images?.map(image => <div><img src={image} alt="Item" /></div>)}
        </ReactCarousel>
    );
};

const ProductDetails = props => {
    const { show, product, hideModal } = props;
    const ADDED = 'ADDED';
    const ADDING = 'ADDING';
    const NOT_ADD = 'NOT_ADD';
    const ADD_TO_CART = 'ADD_TO_CART';

    const [addToCart, setAddToCart] = useState(ADD_TO_CART);
    const [errorMessage, setErrorMessage] = useState(null);

    if (!show) return null;
    const { name, description, images, price, quantity } = product;

    const handleAddToCart = (value) => {
        const { id } = props.product;
        const data = {
            quantity: value
        };

        setAddToCart(ADDING);

        axios.post(APIs.ADD_PRODUCT_TO_CART(id), data).then(res => {
            setAddToCart(ADDED);

            setTimeout(() => {
                const { hideModal } = props;
                setAddToCart(ADD_TO_CART);
                hideModal();

            }, 2000);
        }
        ).catch(err => {
            let message = err.response.data.error.message
            let errorMessage = message || ""
            setErrorMessage(errorMessage)
            setAddToCart(NOT_ADD);

            setTimeout(() => {
                setAddToCart(ADD_TO_CART);
            }, 3000);

        });
    };

    return (
        <Modal
            id="yith-quick-view-modal"
            open={show}
            onClose={() => hideModal()}
            center
        >
            <div className="yith-wcqv-main">
                <div className="yith-wcqv-head" onClick={hideModal}>
                    <a className="yith-wcqv-close hover-pointer">
                        <FontAwesomeIcon icon={faTimesCircle} size={"2x"} />
                    </a>
                </div>
                <div id="yith-quick-view-content" className="woocommerce single-product">
                    <Carousel images={images} />
                    <div className="summary entry-summary ">
                        <div className="summary-content">
                            <h2 itemProp="name" className="mkdf-yith-product-title entry-title">{name}</h2>
                            <p className="price">
                                <ins><span className="woocommerce-Price-amount amount">{price} <span
                                    className="woocommerce-Price-currencySymbol">Points</span>
                                </span></ins>
                            </p>
                            <div className="woocommerce-product-details__short-description">
                                <p>{description}</p>
                            </div>
                            <Quantity quantity={1} state={addToCart} errorMessage={errorMessage} onClick={handleAddToCart} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};


const mapStateToProps = state => {
    return {
        show: state.modal.product.visible,
        product: state.modal.product.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideProductDetails())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);

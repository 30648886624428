import React from 'react';
import '../../App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";

const StarRate = props => {
    const {rate} = props;

    let a = [];
    for(let i = 0; i < rate; i++)
        a.push(<FontAwesomeIcon icon={faStar} {...props} color='#d4af37'/>);
    for(let i = rate; i < 5; i++)
        a.push(<FontAwesomeIcon icon={faStar} {...props}/>);

    return (
        <div className="star-rate">
            {a}
        </div>
    );
};

export default StarRate;
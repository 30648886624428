import React, { Component, Fragment } from 'react';
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import ProductDetails from "./Home/ProductDetails";
import Products from "./Home/Products";

class Home extends Component {
    render = () => {
        return (
            <Fragment>
                <div className="mkdf-wrapper">
                    <div className="mkdf-wrapper-inner">
                        <Header />
                        <HomeContent />
                        <Footer />
                    </div>
                </div>
                <ProductDetails />
                {/* <ProfileModal/> */}
            </Fragment>
        );
    };
}

const HomeContent = props => {
    return (
        <div className="mkdf-content">
            <div className="mkdf-content-inner">
                <div className="mkdf-full-width" style={{ minHeight: 'calc(100vh - 60px)' }}>
                    <div className="mkdf-full-width-inner">
                        <div className="mkdf-grid-row">
                            <div className="mkdf-page-content-holder mkdf-grid-col-12">
                                <div className="mkdf-row-grid-section-wrapper mkdf-disabled-bg-image-bellow-768">
                                    <div className="mkdf-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1528888583995 vc_row-has-fill">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <Products />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mkdf-content-bottom">
                    <div className="mkdf-content-bottom-inner mkdf-full-width">
                        <div className="widget widget_mkdf_instagram_widget" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faFacebookF, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

const Footer = props => {
    return (
        <footer className="mkdf-page-footer ">
            <div className="mkdf-footer-bottom-holder">
                <div className="mkdf-footer-bottom-inner mkdf-grid">
                    <div className="mkdf-grid-row mkdf-footer-content">
                        <div className="mkdf-grid-col-6">
                            <div id="text-6" className="widget mkdf-footer-bottom-column-1 widget_text">
                                <div className="textwidget">
                                    <p style={{textAlign: 'left'}}>© Copyright <a rel="nofollow noopener noreferrer" target="_blank"> Crowns Of Light </a> 2020</p>
                                </div>
                            </div>
                        </div>
                        <div className="mkdf-grid-col-6" style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="mkdf-column-content mkdf-grid-col-3">
                                <a className="mkdf-social-icon-widget-holder mkdf-icon-has-hover mkdf-social-icon-circle"  href="https://www.youtube.com/channel/UCRZjnxQEfzTqll78ENPWbTg"  target="_blank">
                                    <FontAwesomeIcon icon={faYoutube}/>
                                </a>
                                <a className="mkdf-social-icon-widget-holder mkdf-icon-has-hover mkdf-social-icon-circle" style={{backgroundColor: '#55acee', margin: '0 0 0 5px'}} href="https://www.instagram.com/teejan.alnour/" target="_blank">
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                                <a className="mkdf-social-icon-widget-holder mkdf-icon-has-hover mkdf-social-icon-circle" style={{backgroundColor: '#3b5998', margin: '0 0 0 5px'}} href="https://www.fb.com/TeejanAlnour1" target="_blank">
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;

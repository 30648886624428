import React, {Component, Fragment} from "react";
class Product extends Component {
    render = () => {
        return (
            <div className="mkdf-pli mkdf-item-space">
                <div className="mkdf-pli-inner w-100" style={{width: '100%'}}>
                    <div className="mkdf-pli-image">
                        {this.renderBrand()}
                        {this.renderImage()}
                    </div>
                    {this.renderOverlay()}
                </div>
                <div className="mkdf-pli-text-wrapper" style={{marginBottom: '15px'}}>
                    {this.renderName()}
                    {this.renderPrice()}
                </div>
            </div>
        );
    };

    renderBrand = () => {
        return (
            <span className="mkdf-pli-new-product">New</span>
        );
    };

    renderImage = () => {
        const {imageSrc} = this.props;
        return (
            <img width={800} height={889}
                 src={imageSrc}
                 className="attachment-woocommerce_single size-woocommerce_single wp-post-image" alt="d"
                 sizes="(max-width: 800px) 100vw, 800px"/>
        );
    };

    renderName = () => {
        const {name} = this.props;
        return (
            <h5 itemProp="name" className="entry-title mkdf-pli-title">
                <a itemProp="url" > {name} </a>
            </h5>
        );
    };

    renderPrice = () => {
        const {price, unit} = this.props;

        return (
            <div className="mkdf-pli-price">
                <span className="woocommerce-Price-amount amount"> {unit}
                    <span className="woocommerce-Price-currencySymbol"/> {price} </span>
            </div>
        );
    };

    renderOverlay = () => {
        return (
            <Fragment>
                <div className="mkdf-pli-text mkdf-pli-hover-overlay">
                    <div className="mkdf-pli-text-outer">
                        <div className="mkdf-pli-text-inner">
                            {this.renderButton()}
                            <div className="mkdf-quickwish-holder">
                                <div className="yith-wcwl-add-to-wishlist add-to-wishlist-42"> {this.renderBrowseWishList()} </div>
                                <div className="clear"/>
                                <div className="mkdf-yith-wcqv-holder">
                                    <a className="yith-wcqv-button" data-product_id={42}><span className="icon dripicons-preview"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderTitle()}
            </Fragment>
        );
    };

    renderBrowseWishList = () => {
        const {productAddedText} = this.props;
        const {browseWishlistText} = this.props;

        return (
            <div className="yith-wcwl-wishlistaddedbrowse" style={{display: 'block'}}>
                <span className="feedback">{productAddedText}</span>
                <a className="hover-pointer" rel="nofollow">
                    {browseWishlistText}
                </a>
            </div>
        );
    };


    renderButton = () => {
        const {onAddToCart} = this.props;
        const {id} = this.props;
        const {addToCartText} = this.props;

        return (
            <div className="mkdf-pli-add-to-cart mkdf-default-skin">
                <a rel="nofollow" onClick={() => onAddToCart(id)} data-quantity={1} data-product_id={42} data-product_sku={5654} className="button add_to_cart_button ajax_add_to_cart mkdf-button mkdf-pl-btn mkdf-btn mkdf-btn-solid">
                    {addToCartText}
                </a>
            </div>
        );
    };

    renderTitle = () => {
        const {name} = this.props;
        return (
            <a className="mkdf-pli-link hover-pointer" itemProp="url"  title={name}/>
        );
    };

    static defaultProps = {
        unit: ' Points',
        addToWishlistText: 'Add to Wishlist',
        productAddedText: 'Product added!',
        browseWishlistText: 'Browse Wishlist',
        productAlreadyAddedText: 'The product is already in the wishlist!',
        addToCartText: 'Add to Cart',
    };
}

export default Product;

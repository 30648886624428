import React, { Fragment, useState } from 'react';
import '../../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faArrowUp, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";


const Quantity = props => {
    const { quantity } = props;
    const [value, setValue] = useState(quantity);

    const handleOnClickRightCaret = () => {
        setValue(Math.min(99, value + 1));
    };
    const handleOnClickLeftCaret = () => {
        setValue(Math.max(1, value - 1));
    };

    const renderText = () => {
        const { state } = props;
        if (state === "ADDED")
            return "Added";
        else if (state === "NOT_ADD") {
            const { errorMessage } = props;
            if (errorMessage != null)
                return errorMessage
            else
                return "Can't Add More";
        }
        else if (state === "ADD_TO_CART")
            return "Add to Cart";
        else if (state === "ADDING")
            return "Adding ...";
        return "";
    };

    const { onClick } = props;
    const text = renderText();

    return (
        <Fragment>
            <form className="cart" onSubmit={(event) => event.preventDefault()}>
                <div className="mkdf-quantity-buttons quantity">
                    <span className="mkdf-quantity-label"
                        htmlFor="quantity_5ebedfe56ea53">Quantity</span>
                    <FontAwesomeIcon className="hover-pointer" icon={faCaretLeft} size="lg" onClick={handleOnClickLeftCaret} />
                    <input type="text" id="quantity_5ebedfe56ea53"
                        className="input-text qty text mkdf-quantity-input" data-step={1}
                        data-min={1} data-max={1000} name="quantity" defaultValue={quantity}
                        title="Qty" size={4} pattern="[0-9]*" inputMode="numeric"
                        value={value}
                        aria-labelledby />
                    <FontAwesomeIcon className="hover-pointer" icon={faCaretRight} size="lg" onClick={handleOnClickRightCaret} />
                </div>
            </form>
            <button type="submit" name="add-to-cart" value={1129} onClick={() => onClick(value)} className="single_add_to_cart_button button alt">{text}</button>
        </Fragment>
    );
}

export default Quantity;

import React, {Component, Fragment} from 'react';
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Products from "./Home/Products";

const PhotoGallery = (props) => {
    return (
        <Fragment>
            <div className="mkdf-wrapper">
                <div className="mkdf-wrapper-inner">
                    <Header/>
                    <div className="mkdf-content">
                        <div className="mkdf-content-inner">
                            <div className="mkdf-full-width" style={{minHeight: 'calc(100vh - 60px)'}}>
                                <PhotoGalleryContent/>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </Fragment>
    );
};
const PhotoGalleryContent = props => {
    return (
        <div className="mkdf-content">
            <div className="mkdf-content-inner">
                <div className="mkdf-full-width" style={{minHeight: 'calc(100vh - 60px)'}}>
                    <div className="mkdf-full-width-inner">
                        <div className="mkdf-grid-row">
                            <div className="mkdf-page-content-holder mkdf-grid-col-12">
                                <div className="mkdf-row-grid-section-wrapper mkdf-disabled-bg-image-bellow-768">
                                    <div className="mkdf-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1528888583995 vc_row-has-fill">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="about-us">
                                                        <h1>Annual Video 2019-2020:</h1>
                                                        <div
                                                            className="video"
                                                            style={{
                                                                position: "relative",
                                                                paddingBottom: "56.25%" /* 16:9 */,
                                                                paddingTop: 25,
                                                                height: 0
                                                            }}
                                                        >
                                                            <iframe
                                                                style={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: "100%",
                                                                    height: "100%"
                                                                }}
                                                                src={`https://www.youtube.com/embed/Y7P1gkrisUQ`}
                                                                frameBorder="0"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mkdf-content-bottom">
                    <div className="mkdf-content-bottom-inner mkdf-full-width">
                        <div className="widget widget_mkdf_instagram_widget"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PhotoGallery;

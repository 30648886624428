import axios from "axios";
import React from 'react';
import { Switch } from "react-bootstrap";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import './App.css';
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Home from "./Pages/Home";
import Cart from "./Pages/Home/Cart.js";
import Login from "./Pages/Home/Login.js";
import PhotoGallery from "./Pages/PhotoGallery";
import Profile from './Pages/Profile';
import UpdateProfile from "./Pages/UpdateProfile";

const auth = localStorage.getItem("Authorization");

if (auth) {
    axios.defaults.headers.common = {
        Authorization: auth,
    };
}

function App() {

    // let isAllowedToAccessConent = () => {
    //     //     return true;

    //     let currentDate = new Date()
    //     let publishDate = new Date('05-07-2021 00:30 GMT+0300')
    //     // let publishDate = new Date('05-06-2021 21:30  GMT+0300')
    //     console.log("currentDate")
    //     console.log(currentDate)
    //     console.log("publishDate2")
    //     console.log(publishDate)
    //     if (currentDate.getTime() > publishDate.getTime())
    //         return true;
    //     return false;
    // }
    // if (!isAllowedToAccessConent()) {
    //     return (
    //         <img width='100%' height='100%' src="/assets/img/soon.png" alt=" logo"></img>
    //     );
    // }

    return (
        <Router>
            <Switch>
                <Route exact path="/"> <Home /> </Route>
                <Route exact path="/login"> <Login /> </Route>
                <Route exact path="/cart"> <Cart /> </Route>
                <Route exact path="/profile"> <Profile /> </Route>
                <Route exact path="/update-profile"> <UpdateProfile /> </Route>
                <Route exact path="/about-us"> <AboutUs /> </Route>
                <Route exact path="/contact-us"> <ContactUs /> </Route>
                <Route exact path="/photo-gallery"> <PhotoGallery /> </Route>
                {!auth && <Redirect to="/login" />}
            </Switch>
        </Router>
    );

}

export default App;

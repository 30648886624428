import React, { Component } from "react";
import classes from 'classnames';
import { APIs } from "../../APIs";
import { _name } from "../../Local";

import axios from "axios";
import { Image } from "react-bootstrap";
import { setCategory, showProductDetails } from "../../actions/ModalAction";
import { connect } from "react-redux";
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css';
import { useMediaQuery } from 'react-responsive';

class CategoriesFilter extends Component {
    state = {
        categories: [],
        selectedCategory: null,
    };

    componentDidMount() {
        axios.get(APIs.CATEGORY_API).then(res => {
            let { data: categories } = res;
            categories = [{
                id: -1,
                nameEn: 'All Categories',
                media: {
                    url: 'https://cdn.iconscout.com/icon/premium/png-512-thumb/categories-988842.png',
                }
            }, ...categories];

            this.setState({
                categories: categories,
                selectedCategory: categories[0],
            })
        }
        );
    }

    render = () => {
        const { categories, selectedCategory } = this.state;

        return (
            <div className="mkdf-product-categories-holder clearfix mkdf-normal-space mkdf-six-columns">
                <div className="mkdf-product-categories-holder-inner clearfix ">
                    <CategoriesFilter.Carousel onClick={this.handleClick} categories={categories} selectedCategory={selectedCategory} />
                </div>
            </div>
        );
    };

    handleClick = (category) => {
        this.setState({
            ...this.state,
            selectedCategory: category,
        });

        const { setCategory } = this.props;
        setCategory(category);

        const { onChange } = this.props;
        const { selectedCategory } = this.state;
        if (typeof onChange === 'function')
            onChange(selectedCategory);
    };
}


const CategoriesFilterCarousel = props => {
    const { categories, selectedCategory, onClick } = props;
    const sizes = {
        xs: '(max-width: 600px)',
        sm: '(max-width: 960px) and (min-width: 601px)',
        md: '(max-width: 1280px) and (min-width: 961px)',
        lg: '(max-width: 1920px) and (min-width: 1281px)',
        gtXs: '(min-width: 601px)',
        gtSm: '(min-width: 961px)',
        gtMd: '(min-width: 1281px)',
        gtLg: '(min-width: 1921px)',
    };

    const xs = useMediaQuery({ query: sizes.xs });
    const sm = useMediaQuery({ query: sizes.sm });
    const md = useMediaQuery({ query: sizes.md });
    const lg = useMediaQuery({ query: sizes.lg });

    let count = 5;
    if (xs) {
        count = 1;
    } else if (sm) {
        count = 2;
    } else if (md) {
        count = 3;
    } else if (lg) {
        count = 4;
    }

    return (
        <Carousel slidesPerPage={count} arrows>
            {categories.map((category, index) =>
                <CategoriesFilter.Item
                    key={index}
                    id={category.id}
                    name={_name(category)}
                    image={category.media.url}
                    active={category === selectedCategory}
                    onClick={() => onClick(category)}
                />)}
        </Carousel>
    );
};
CategoriesFilter.Carousel = (props) => <CategoriesFilterCarousel {...props} />;

CategoriesFilter.Item = props => {
    const { active, name, image, onClick } = props;

    return (
        <div className={classes("mkdf-product-category mkdf-category-with-image", { active: active })} onClick={onClick}>
            <div className="mkdf-product-category-inner">
                <a className="mkdf-link hover-pointer" />
                <div className="mkdf-product-category-img-wrapper">
                    <div className="mkdf-pcw-inner">
                        <Image className="mkdf-category-image" roundedCircle={true} src={image} />
                    </div>
                </div>
                <div className="mkdf-product-category-content">
                    <h6 className="mkdf-category-title">{name} </h6>
                </div>
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        setCategory: category => dispatch(setCategory(category))
    };
};

export default connect(null, mapDispatchToProps)(CategoriesFilter);

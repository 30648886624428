import React, {Component, Fragment} from 'react';
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Products from "./Home/Products";

const AboutUs = (props) => {
    return (
        <Fragment>
            <div className="mkdf-wrapper">
                <div className="mkdf-wrapper-inner">
                    <Header/>
                    <div className="mkdf-content">
                        <div className="mkdf-content-inner">
                            <div className="mkdf-full-width" style={{minHeight: 'calc(100vh - 60px)'}}>
                                <AboutUsContent/>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </Fragment>
    );
};
const AboutUsContent = props => {
    return (
        <div className="mkdf-content">
            <div className="mkdf-content-inner">
                <div className="mkdf-full-width" style={{minHeight: 'calc(100vh - 60px)'}}>
                    <div className="mkdf-full-width-inner">
                        <div className="mkdf-grid-row">
                            <div className="mkdf-page-content-holder mkdf-grid-col-12">
                                <div className="mkdf-row-grid-section-wrapper mkdf-disabled-bg-image-bellow-768">
                                    <div className="mkdf-row-grid-section">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1528888583995 vc_row-has-fill">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="about-us">
                                                        <h1>Contact us</h1>
                                                        <p>If you wish to enquire about any of our programs, don't hesitate to contact us</p>
                                                        <ul>
                                                            <li><a href="tel:+1(514) 502 9992">Call: +1(514) 502 9992</a></li>
                                                            <li><a href="tel:+1(514) 838 4033">Call: +1(514) 838 4033</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mkdf-content-bottom">
                    <div className="mkdf-content-bottom-inner mkdf-full-width">
                        <div className="widget widget_mkdf_instagram_widget"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default AboutUs;

import {modalTypes} from "./modalTypes";

export const showProductDetails = product => {
    return {
        type: modalTypes.SHOW_PRODUCT_DETAILS,
        product: product,
    }
};

export const hideProductDetails = () => {
    return {
        type: modalTypes.HIDE_PRODUCT_DETAILS,
    }
};


export const showProfile = product => {
    return {
        type: modalTypes.SHOW_PROFILE,
    }
};

export const hideProfile = () => {
    return {
        type: modalTypes.HIDE_PROFILE,
    }
};


export const setCategory = category => {
    return {
        type: modalTypes.SET_CATEGORY,
        category: category,
    }
};

// const BASE_URL = "http://157.90.150.185:3001/api/";
// const BASE_URL = "http://localhost:3000/api/";
const BASE_URL = "https://www.crownsoflight.ca:3000/api/";

export const APIs = {
    CATEGORY_API: BASE_URL + "categories",
    PRODUCTS_API: BASE_URL + "products",
    LOGIN_API: BASE_URL + "users/login?include=user",
    ADD_PRODUCT_TO_CART: (id) => BASE_URL + `products/${id}/addProductToCart`,
    REMOVE_PRODUCT: (id) => BASE_URL + `cartProducts/${id}/deleteProduct`,
    CART_API: BASE_URL + "users/myCart",
    CHECKOUT: BASE_URL + "purchases/makePurchase",
    ME: BASE_URL + "users/me",
    MY_PURCHASE: BASE_URL + "users/MyPurchase",
    UPDATE_PROFILE: BASE_URL + "users/updateInfo",
};
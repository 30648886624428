import {modalTypes} from "../actions/modalTypes";

const initState = {
    product: {
        visible: false,
        data: {}
    },
    category: {
        id: -1,
    },
    profile: {
        visible: false,
    }
};
const ModalReducer = (state = initState, action) => {
    switch (action.type) {
        case modalTypes.SHOW_PRODUCT_DETAILS :
            return Object.assign({}, state, {
                ...state,
                product: {
                    visible: true,
                    data: action.product
                },
            });

        case modalTypes.SHOW_PROFILE :
            return Object.assign({}, state, {
                ...state,
                profile: {
                    visible: true,
                },
            });

        case modalTypes.HIDE_PRODUCT_DETAILS :
            return Object.assign({}, state, {
                ...state,
                product: {
                    visible: false,
                    data: null,
                },
            });


        case modalTypes.HIDE_PROFILE :
            return Object.assign({}, state, {
                ...state,
                profile: {
                    visible: false,
                },
            });

        case modalTypes.SET_CATEGORY:
            return Object.assign({}, state, {
                ...state,
                category: action.category,
            });

        default:
            return state;
    }
};

export default ModalReducer;

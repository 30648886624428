import React, {Component} from 'react';
import '../../App.css';
import CategoriesFilter from "./CategoriesFilter";
import Pagination from "../../Components/Pagination";
import axios from "axios";
import Product from "./Product";
import {APIs} from "../../APIs";

class LoadingFeedback extends Component {
    render = () => {
        return "Loading";
    };
}

export default LoadingFeedback;
import axios from 'axios';
import classes from 'classnames';
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { showProductDetails } from "../../actions/ModalAction";
import { APIs } from "../../APIs";
import CategoriesFilter from "./CategoriesFilter";
import ErrorFeedback from "./ErrorFeedback";
import LoadingFeedback from "./LoadingFeedback";
import StarRate from "./StarRate";

class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            loading: false,
            error: false,
        }
        this.isInStock = true;
        this.filterByPoints = null;
    };

    render = () => {
        const { products } = this.state;
        const { selectedCategory } = this.props;
        const isInStock = this.isInStock;
        const filterByPoints = this.filterByPoints;
        console.log(selectedCategory);

        const { loading, error } = this.state;

        if (loading)
            return <LoadingFeedback />;
        if (error)
            return <ErrorFeedback />;

        return (
            <div className="wpb_wrapper">
                <div className="mkdf-row-grid-section-wrapper mkdf-content-aligment-center">
                    <div className="mkdf-row-grid-section">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1527598313482">
                            <div className="wpb_column vc_column_container vc_col-sm-10 vc_col-lg-offset-3 vc_col-lg-6 vc_col-md-offset-2 vc_col-md-8 vc_col-sm-offset-1">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="mkdf-section-title-holder    mkdf-st-subtitle-pos-under">
                                            <div className="mkdf-st-inner">
                                                <div>
                                                    <h1 className="mkdf-st-title">
                                                        Welcome to Rewards & Gifts store
                                                    </h1>
                                                </div>
                                                <p className="mkdf-st-text" style={{ marginTop: '15px' }}>
                                                    Enjoy shopping with Teejan Al Nour
                                                    Have FUN 🙂 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mkdf-pl-holder mkdf-standard-layout mkdf-normal-space">
                    <h5>Filter by points</h5>
                    <div className=" mkdf-product-categories-holder clearfix mkdf-normal-space mkdf-six-columns" style={{ marginTop: '-10px', marginLeft: '40px', marginRight: '40px' }}>
                        <div className="mkdf-product-categories-holder-inner clearfix">

                            <div className={classes("mkdf-product-category mkdf-category-with-image", { active: filterByPoints == null })} onClick={() => this.handleFilterPointsClick(null)}>
                                <div className="mkdf-product-category-inner">
                                    <a className="mkdf-link hover-pointer" />
                                    <div className="mkdf-product-category-content">
                                        <h6 className="mkdf-category-title"> All </h6>
                                    </div>
                                </div>
                            </div>

                            <div className={classes("mkdf-product-category mkdf-category-with-image", { active: filterByPoints == 1 })} onClick={() => this.handleFilterPointsClick(1)}>
                                <div className="mkdf-product-category-inner">
                                    <a className="mkdf-link hover-pointer" />
                                    <div className="mkdf-product-category-content">
                                        <h6 className="mkdf-category-title">1-200 points </h6>
                                    </div>
                                </div>
                            </div>

                            <div className={classes("mkdf-product-category mkdf-category-with-image", { active: filterByPoints == 2 })} onClick={() => this.handleFilterPointsClick(2)}>
                                <div className="mkdf-product-category-inner">
                                    <a className="mkdf-link hover-pointer" />
                                    <div className="mkdf-product-category-content">
                                        <h6 className="mkdf-category-title">201-500 points </h6>
                                    </div>
                                </div>
                            </div>
                            <div className={classes("mkdf-product-category mkdf-category-with-image", { active: filterByPoints == 3 })} onClick={() => this.handleFilterPointsClick(3)}>
                                <div className="mkdf-product-category-inner">
                                    <a className="mkdf-link hover-pointer" />
                                    <div className="mkdf-product-category-content">
                                        <h6 className="mkdf-category-title">More than 500 points </h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="mkdf-pl-holder mkdf-standard-layout mkdf-normal-space"> */}
                    <h5>Filter by categories</h5>
                    <CategoriesFilter />
                    {/* </div> */}
                    {/* <div className="mkdf-pl-holder mkdf-standard-layout mkdf-normal-space" style={{ marginBottom: '10px', marginTop: '-30px', marginLeft: '40px' }}> */}
                    <div className="mkdf-product-categories-holder clearfix mkdf-normal-space mkdf-six-columns" style={{ marginTop: '10px', marginLeft: '40px' }}>
                        <div className="wpb_column vc_column_container vc_col-sm-2 vc_col-lg-2 vc_col-md-2">
                            <div className="mkdf-product-categories-holder-inner clearfix ">
                                <div className={classes("mkdf-product-category mkdf-category-with-image", { active: isInStock })} onClick={this.handleIsInStockClick}>
                                    <div className="mkdf-product-category-inner" style={{ padding: '5px 5px' }}>
                                        <a className="mkdf-link hover-pointer" />
                                        <div className="mkdf-product-category-content">
                                            <h6 className="mkdf-category-title"> {isInStock ? ' In stock items' : ' All items'} </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mkdf-pl-holder mkdf-standard-layout mkdf-normal-space mkdf-four-columns mkdf-info-below-image" data-type="standard" data-info-position="info-below-image" data-enable-fixed-proportions="no" data-number-of-posts={8} data-number-of-columns={4} data-space-between-items="normal" data-show-ordering-filter="no"
                    data-orderby="date" data-order="ASC" data-show-category-filter="no" data-show-all-item-in-filter="yes" data-taxonomy-to-display="category" data-taxonomy-values="babies" data-enable-appear-animation="yes" data-display-title="yes" data-title-tag="h5" data-display-category="no" data-display-rating="yes"
                    data-display-price="yes" data-display-button="yes" data-button-skin="default" data-info-bottom-margin="15px" data-class-name="pli" data-layout-classes="mkdf-pli-hover-overlay">
                    <div className="mkdf-prl-loading">
                        <span className="mkdf-prl-loading-msg">Loading...</span>
                    </div>
                    <div className="mkdf-pl-outer mkdf-outer-space">
                        {products
                            .filter(product => product.quantity > 0 || isInStock === false)
                            .filter(product => product.categoryId === selectedCategory?.id || selectedCategory?.id === -1)
                            .filter(product => {
                                if (filterByPoints == 1) {
                                    return product.price >= 1 && product.price <= 200;
                                }
                                if (filterByPoints == 2) {
                                    return product.price >= 201 && product.price <= 500;
                                }
                                if (filterByPoints == 3) {
                                    return product.price >= 501;
                                }
                                return true;
                            })
                            .map((product, index) =>
                                <Product
                                    key={index}
                                    id={product.id}
                                    name={product.name}
                                    price={product.price}
                                    rate={product.rate}
                                    imageSrc={product.imageSrc}
                                    quantity={product.quantity}
                                    onViewDetailsClick={() => this.handleViewDetailsClick(product)}
                                />
                            )}
                    </div>
                </div>

            </div>
        );
    };

    handleViewDetailsClick = product => {
        const { showProductDetails } = this.props;
        showProductDetails(product);
    };

    handleIsInStockClick = () => {
        this.isInStock = !this.isInStock;
        this.setState({
            ...this.state,
        });
        // this.setState({
        //     ...this.state,
        //     selectedCategory: category,
        // });

        // const {setCategory} = this.props;
        // setCategory(category);

        // const {onChange} = this.props;
        // const {selectedCategory} = this.state;
        // if (typeof onChange === 'function')
        //     onChange(selectedCategory);
    };

    handleFilterPointsClick = (filterPointsValue) => {

        this.filterByPoints = filterPointsValue;
        this.setState({
            ...this.state,
        });
        // this.setState({
        //     ...this.state,
        //     selectedCategory: category,
        // });

        // const {setCategory} = this.props;
        // setCategory(category);

        // const {onChange} = this.props;
        // const {selectedCategory} = this.state;
        // if (typeof onChange === 'function')
        //     onChange(selectedCategory);
    };

    componentDidMount = () => {
        this.setState({
            loading: true,
            error: false,
        });

        axios.get(APIs.PRODUCTS_API).then(
            res => {
                const { data } = res;

                this.setState({
                    ...this.state,
                    loading: false,
                    error: false,
                    products: data.map(product => {
                        const { media } = product;

                        return {
                            ...product,
                            name: product.nameEn,
                            description: product.descriptionEn,
                            imageSrc: media[0]?.url,
                            images: media.map(item => item.url)
                        }
                    }),
                })
            }
        ).catch(e => {
            this.setState({
                ...this.state,
                loading: false,
                error: true,
            })
        });
    };

}

class Product extends Component {
    render = () => {
        return (
            <div className="mkdf-pli mkdf-item-space">
                <div className="mkdf-pli-inner" style={{ width: '100%' }}>
                    <div className="mkdf-pli-image">
                        {this.renderBrand()}
                        {this.renderImage()}
                    </div>
                    {this.renderOverlay()}
                </div>
                <div className="mkdf-pli-text-wrapper" style={{ marginBottom: '15px' }}>
                    {this.renderName()}
                    {this.renderRate()}
                    {this.renderPrice()}
                </div>
            </div>
        );
    };

    renderBrand = () => {
        const { quantity } = this.props;
        if (quantity == 0)
            return (
                <span className="mkdf-pli-new-product">Out of stock</span>
            );
        else
            return (
                <span className="mkdf-pli-new-product">{quantity}</span>
            );
    };

    renderImage = () => {
        const { imageSrc } = this.props;
        return (
            <img width={800} height={889} src={imageSrc} className="attachment-woocommerce_single size-woocommerce_single wp-post-image" alt="Missing image" sizes="(max-width: 800px) 100vw, 800px" />
        );
    };

    renderName = () => {
        const { name } = this.props;
        return (
            <h5 itemProp="name" className="entry-title mkdf-pli-title">
                <a itemProp="url"> {name} </a>
            </h5>
        );
    };

    renderPrice = () => {
        const { price, unit } = this.props;

        return (
            <div className="mkdf-pli-price">
                <span className="woocommerce-Price-amount amount">  <span className="woocommerce-Price-currencySymbol" /> {price} </span>{unit}
            </div>
        );
    };

    renderRate = () => {
        const { rate } = this.props;
        return (
            <StarRate rate={rate} />
        );
    };

    renderOverlay = () => {
        return (
            <Fragment>
                <div className="mkdf-pli-text mkdf-pli-hover-overlay">
                    <div className="mkdf-pli-text-outer">
                        <div className="mkdf-pli-text-inner">
                            {this.renderButton()}
                        </div>
                    </div>
                </div>
                {this.renderTitle()}
            </Fragment>
        );
    };

    renderButton = () => {
        const { onViewDetailsClick } = this.props;
        const ViewDetailsText = "View Details";

        return (
            <div className="mkdf-pli-add-to-cart mkdf-default-skin">
                <a rel="nofollow" onClick={onViewDetailsClick} data-quantity={1} data-product_id={42} data-product_sku={5654} className="button add_to_cart_button ajax_add_to_cart mkdf-button mkdf-pl-btn mkdf-btn mkdf-btn-solid">
                    {ViewDetailsText}
                </a>
            </div>
        );
    };

    renderTitle = () => {
        const { name } = this.props;
        return (
            <a className="mkdf-pli-link" itemProp="url" title={name} />
        );
    };


    static defaultProps = {
        unit: ' Points',
        addToWishlistText: 'Add to Wishlist',
        productAddedText: 'Product added!',
        browseWishlistText: 'Browse Wishlist',
        productAlreadyAddedText: 'The product is already in the wishlist!',
        addToCartText: 'Add to Cart',
    };
}


const mapDispatchToProps = dispatch => {
    return {
        showProductDetails: product => dispatch(showProductDetails(product))
    };
};

const mapStateToProps = state => {
    return {
        selectedCategory: state.modal.category,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

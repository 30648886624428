import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Component, Fragment } from 'react';
import Swal from 'sweetalert2';
import { APIs } from "../../APIs";
import '../../App.css';
import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import LoadingFeedback from "./LoadingFeedback";
import ProductDetails from "./ProductDetails";

class Cart extends Component {
    render = () => {
        return (
            <Fragment>
                <div className="mkdf-wrapper mkdf-full-width">
                    <div className="mkdf-wrapper-inner">
                        <Header />
                        <CartContent />
                        <Footer />
                    </div>
                </div>
                <ProductDetails />
                {/* <ProfileModal /> */}
            </Fragment>
        );
    };
}

class CartContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            errorProducts: null,
        }
    };

    render = () => {
        return (
            <div className="mkdf-row-grid-section-wrapper " style={{ marginTop: 200, minHeight: 'calc(100vh - 260px)' }}>
                <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1528787784019">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    };

    renderContent = () => {
        const { products, errorProducts, loading } = this.state;
        if (loading) {
            return (
                <LoadingFeedback />
            );
        }

        if (products.length === 0) {
            return (
                <div>
                    <div className="woocommerce mkdf-empty-cart">
                        <p className="cart-empty">Your cart is currently empty.</p>
                        <br />
                        <p className="return-to-shop">
                            <a className="button wc-backward" href="/">
                                Return to shop
                            </a>
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid mkdf-content-aligment-center">
                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-3 vc_col-lg-6 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0 vc_col-xs-12">
                        <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                                <div className="mkdf-section-title-holder mkdf-st-subtitle-pos-under">
                                    <div className="mkdf-st-inner">
                                        <div>
                                            <h2 className="mkdf-st-title"> My Cart </h2>
                                        </div>
                                        <p className="mkdf-st-text" style={{ fontSize: '19px', marginTop: '14px' }}>
                                            The rewards that you have chosen
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mkdf-pls-holder mkdf-pls-best-sellers mkdf-small-space mkdf-four-columns">
                    <ul className="mkdf-pls-inner">
                        {products.map((item, index) =>
                            <li className="mkdf-pls-item">
                                <div className="mkdf-pls-image">
                                    <span className="mkdf-pli-out-of-stock" onClick={() => this.handleDeleteProduct(item)}>
                                        <FontAwesomeIcon className="icon" icon={faTrashAlt} />
                                    </span>
                                    <a itemProp="url" className="hover-pointer" title="Pony Toy">
                                        <img width={600} height={675} src={item.product.media[0].url} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image" /> </a>
                                </div>
                                <div className="mkdf-pls-text">
                                    <h6 itemProp="name" className="entry-title mkdf-pls-title">
                                        <a itemProp="url" className="hover-pointer">{item.product.nameEn}</a>
                                    </h6>
                                    <h6 itemProp="name" className="entry-title mkdf-pls-title">
                                        <a itemProp="url" className="hover-pointer">{`${item.quantity} item`}</a>
                                    </h6>

                                    <div className="mkdf-pls-price"><span className="woocommerce-Price-amount amount">
                                        {`${item.quantity * item.product.price} Points`}
                                    </span>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                {errorProducts && errorProducts.length && <div className="mkdf-pls-holder mkdf-pls-best-sellers mkdf-small-space mkdf-four-columns">

                    <p className="mkdf-st-text" style={{ fontSize: '19px', fontWeight: 'bold', marginTop: '40px', color: '#ff0000' }}>
                        The products below are not available with selected quantity
                                        </p>
                    <ul className="mkdf-pls-inner">
                        {errorProducts.map((item, index) =>
                            <li>
                                <div className="mkdf-pls-text">
                                    <h6 itemProp="name" className="entry-title mkdf-pls-title">
                                        <a itemProp="url" className="hover-pointer">{item.nameEn}</a>
                                    </h6>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                }
                {products.length && <div className="checkout-container">

                    {/* <a href="https://forms.gle/ZaRhHEZYJ4jBqtAE9" className={"cancel-all"}>
                        <div className="mkdf-btn mkdf-btn-medium mkdf-btn-solid mkdf-btn-icon mkdf-btn-icon-animate mkdf-btn-cf7-medium" style={{ marginTop: 60, marginLeft: 5, marginRight: 5, width: '100#' }}>
                            <input type="submit" value="Delivery Address" defaultValue="Subscribe" className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon" />
                            <span className="ajax-loader" />
                            <span className="mkdf-btn-icon-holder">
                                <span className="mkdf-btn-icon-holder-inner">
                                    <i className="mkdf-icon-dripicons dripicon dripicons-rocket mkdf-btn-icon-elem" />
                                    <i className="mkdf-icon-dripicons dripicon dripicons-location mkdf-btn-icon-elem" />
                                </span>
                            </span>
                        </div>
                    </a> */}

                    <div className="mkdf-btn mkdf-btn-medium mkdf-btn-solid mkdf-btn-icon mkdf-btn-icon-animate mkdf-btn-cf7-medium"
                        onClick={this.checkout}
                        style={{ marginTop: 60, marginLeft: 5, marginRight: 5, width: '100#' }}>
                        <input type="submit" value="Checkout" defaultValue="Subscribe" className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon" />
                        <span className="ajax-loader" />
                        <span className="mkdf-btn-icon-holder">
                            <span className="mkdf-btn-icon-holder-inner">
                                <i className="mkdf-icon-dripicons dripicon dripicons-rocket mkdf-btn-icon-elem" />
                                <i className="mkdf-icon-dripicons dripicon dripicons-cart mkdf-btn-icon-elem" />
                            </span>
                        </span>
                    </div>

                </div>
                }
            </div>

        );
    };

    componentDidMount = () => {
        this.fetchProducts();
    };


    handleDeleteProduct = (product) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete the item from the cart`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Delete'
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });

                const self = this;
                axios.delete(APIs.REMOVE_PRODUCT(product.id)).then(
                    res => {
                        self.fetchProducts();
                    }
                ).catch(error => console.log(error));
            }
        });
    };

    fetchProducts = () => {
        this.setState({
            ...this.state,
            loading: true,
        });
        axios.get(APIs.CART_API).then(
            res => {
                const { data } = res;
                this.setState({
                    ...this.state,
                    loading: false,
                    products: data,
                });

            }
        );
    };

    checkout = () => {
        var products = this.state.products;
        const total = this.state.products.reduce((result, item) => {
            return result + item.quantity * item.product.price;
        }, 0);

        Swal.fire({
            title: 'Are you sure?',
            text: `You will pay ${total} Points in total`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Checkout'
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });

                const self = this;
                axios.post(APIs.CHECKOUT).then(
                    res => {
                        self.fetchProducts();
                        self.setState({
                            ...this.state,
                            loading: false,
                        });

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer);
                                toast.addEventListener('mouseleave', Swal.resumeTimer);
                            }
                        });

                        Toast.fire({
                            icon: 'success',
                            title: 'successfully checked out'
                        })
                    }
                ).catch(error => {
                    console.log(error);
                    let responseError = error.response.data.error;
                    console.log(responseError);
                    if (responseError.code == 453) {
                        this.setState({
                            ...this.state,
                            loading: false,
                            products: products,
                            errorProducts: responseError.details
                        });
                    }
                    //453
                    // setAddToCart(NOT_ADD);

                    // setTimeout(() => {
                    //     setAddToCart(ADD_TO_CART);
                    // }, 3000);

                });
            }
        });
    }
}

export default Cart;

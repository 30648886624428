import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import RootReducer from "../reducers/RootReducer";

const composeEnhancers = composeWithDevTools({
    realtime: true, hostname: 'localhost', port: 8000
});
const store = createStore(RootReducer, composeEnhancers(
    applyMiddleware(thunk),
));

export default store;

import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import { APIs } from '../APIs';
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import LoadingFeedback from './Home/LoadingFeedback';

class Profile extends Component {

    render = () => {
        return (
            <Fragment>
                <div className="mkdf-wrapper">
                    <div className="mkdf-wrapper-inner">
                        <Header />
                        <ProfileContent />
                        <Footer />
                    </div>
                </div>
            </Fragment>
        );
    };
}

class ProfileContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        }
    };

    render = () => {
        return (
            <div className="mkdf-row-grid-section-wrapper " style={{ marginTop: 200, minHeight: 'calc(100vh - 260px)' }}>
                <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1528787784019">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderContent = () => {
        const { user, purchases, loading } = this.state;
        console.log("user is")
        console.log(user)
        if (loading) {
            return (
                <LoadingFeedback />
            );
        }
        const hasPurchases = purchases && purchases.length > 0;
        return (
            <div className="wpb_wrapper">
                {user &&
                    <div style={{ marginBottom: '20px' }}>
                        <div className="mkdf-section-title-holder mkdf-st-subtitle-pos-under mkdf-content-aligment-center">
                            <div className="mkdf-st-inner">
                                <div>
                                    <h2 className="mkdf-st-title">  Hello <a>{user.name}</a> </h2>
                                </div>
                                <div>
                                    <h3 className="mkdf-st-title" style={{ marginTop: '14px', marginBottom: '30px', }}>   You have <a> {user.points}</a> Points</h3>
                                </div>
                            </div>
                        </div>


                        <div className="mkdf-section-title-holder mkdf-st-subtitle-pos-under">
                            <div className="mkdf-st-inner">
                                <div>
                                    <h4 className="mkdf-st-title" >
                                        Basic information</h4>
                                </div>
                            </div>
                        </div>
                        <div className="profile-info">
                            <ul>
                                <li><span>Name </span> {user.name}</li>
                                <li><span>Phone</span> {user.phone}</li>
                                <li><span>Civic number</span> {user.civicNumber}</li>
                                <li><span>Street name</span> {user.streetName}</li>
                                <li><span>Apartment number</span> {user.apartmentNumber}</li>
                                <li><span>Postal Code</span> {user.postalCode}</li>
                                <li><span>City</span> {user.city}</li>
                            </ul>
                        </div>


                        <a href="/update-profile" className={"cancel-all"}>
                            <div className="mkdf-btn mkdf-btn-medium mkdf-btn-solid mkdf-btn-icon mkdf-btn-icon-animate mkdf-btn-cf7-medium" style={{ marginTop: 60, marginLeft: 5, marginRight: 5, width: '100#' }}>
                                <input type="submit" value="Address" defaultValue="Subscribe" className="wpcf7-form-control wpcf7-submit mkdf-cf7-submit-with-icon" />
                                <span className="ajax-loader" />
                                <span className="mkdf-btn-icon-holder">
                                    <span className="mkdf-btn-icon-holder-inner">
                                        <i className="mkdf-icon-dripicons dripicon dripicons-rocket mkdf-btn-icon-elem" />
                                        <i className="mkdf-icon-dripicons dripicon dripicons-location mkdf-btn-icon-elem" />
                                    </span>
                                </span>
                            </div>
                        </a>
                    </div >
                }
                {
                    hasPurchases &&
                    <div style={{ marginTop: '40px' }}>
                        <div className="mkdf-section-title-holder mkdf-st-subtitle-pos-under">
                            <div className="mkdf-st-inner">
                                <div>
                                    <h4 className="mkdf-st-title" >
                                        The rewards that you have bought</h4>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '40px' }}>
                            <div className="mkdf-pls-holder mkdf-pls-best-sellers mkdf-small-space mkdf-four-columns">
                                <ul className="mkdf-pls-inner">
                                    {purchases.map((item, index) =>
                                        <li className="mkdf-pls-item">
                                            <div className="mkdf-pls-image">
                                                <a itemProp="url" className="hover-pointer" title="Pony Toy">
                                                    <img width={600} height={675} src={item.product.media[0].url} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image" /> </a>
                                            </div>
                                            <div className="mkdf-pls-text">
                                                <h6 itemProp="name" className="entry-title mkdf-pls-title">
                                                    <a itemProp="url" className="hover-pointer">{item.product.nameEn}</a>
                                                </h6>
                                                <h6 itemProp="name" className="entry-title mkdf-pls-title">
                                                    <a itemProp="url" className="hover-pointer">{`${item.quantity} item`}</a>
                                                </h6>

                                                <div className="mkdf-pls-price"><span className="woocommerce-Price-amount amount">
                                                    {`${item.quantity * item.product.price} Points`}
                                                </span>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div >
        );
    };

    componentDidMount = () => {
        this.fetchProfile();
    };


    fetchProfile = () => {
        this.setState({
            ...this.state,
            loading: true,
        });

        Axios.get(APIs.ME).then(
            res => {
                this.setState({
                    ...this.state,
                    user: res.data,
                    purchases: [],
                    loading: false,
                });

                this.fetchMyPurchases();
            }
        );

    };

    fetchMyPurchases = () => {
        Axios.get(APIs.MY_PURCHASE).then(
            res => {
                this.setState({
                    ...this.state,
                    purchases: res.data,
                    loading: false,
                });
            }
        );
    };

}

export default Profile;
